import React, { Component } from "react";
import _ from "lodash";
import { Link } from "gatsby";
import styled from "styled-components";

const CategoryText = styled(Link)`
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: .15em;
  font-size: 1rem;
  font-weight: 600;
  text-indent: .25em;
  color: var(--color-font);
  margin-right: 0.375em;
  &:hover {
    color: var(--color-purple);
  }
`

class Categories extends Component {
  render() {
    const { categories } = this.props;
    return (
      <div className="tag-container">
        {categories &&
          categories.map(category => (
            <CategoryText
            className="tag-item"
            key={category}
            to={`/categories/${_.kebabCase(category)}`}
            >
              {category.toUpperCase()}
            </CategoryText>
          ))}
      </div>
    );
  }
}

export default Categories;
