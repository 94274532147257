import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Categories from "../components/Categories"
import InnerPadding from "../components/InnerPadding"

const BlogPostTemplate = (props) => {
  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext

    return (
      <Layout title={siteTitle}>
        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <InnerPadding>
          <article className="post-container">
            <section className="post-header">
              <h1>
                {post.frontmatter.title}
              </h1>
              <p>
                {post.frontmatter.date}
              </p>
              {post.frontmatter.thumbnail && <GatsbyImage 
                image={post.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                alt={post.frontmatter.title}
              />}
            </section>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
            <Categories categories={post.frontmatter.categories}/>
          </article>
          <hr className="contour"/>
          <nav>
            <ul className="post-nav">
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    <svg transform="scale(-1, 1)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
                      <g><path d="M767.9,499.9L291.6,10l-59.4,61.3l416.6,428.7L232.1,928.7l59.5,61.3L767.9,499.9z"/></g>
                    </svg>
                    <span className="post-nav-prev">{previous.frontmatter.title}</span>
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    <span className="post-nav-next">{next.frontmatter.title}</span>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
                      <g><path d="M767.9,499.9L291.6,10l-59.4,61.3l416.6,428.7L232.1,928.7l59.5,61.3L767.9,499.9z"/></g>
                    </svg>
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </InnerPadding>
      </Layout>
    )
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        categories
        description
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 700)
          }
        }
      }
    }
  }
`
